<template>
	<div class="reservation_infos">
		<div class="title">Web Check-In</div>
		<div class="reservation_number">{{ getTranslation('RESERVATION_NUMBER') }}: <b>BOL_{{ reservation_id }}</b></div>
		<div class="reservation_dates"><span>{{ getTranslation('CHECKIN') }}: <b>{{ check_in }}</b></span><span>{{ getTranslation('CHECKOUT') }}: <b>{{ check_out }}</b></span></div>
	</div>
</template>

<script>
	export default {
		name: 'ReservationInfos',
		computed: {
			reservation_id: function() {
				return this.$store.state.reservation_id;
			},
			check_in: function() {
				return this.$store.state.reservation_data.check_in_formatted;
			},
			check_out: function() {
				return this.$store.state.reservation_data.check_out_formatted;
			},
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
		}
	}
</script>

<style type="text/css" scoped>
	.reservation_infos {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
	}

	.title {
		font-size: 26px;
		font-weight: 700;
	}

	.reservation_number {
		margin-top: 15px;
	}

	.reservation_dates {
		margin-top: 10px;
	}

	.reservation_dates span:nth-child(1) {
		margin-right: 20px;
	}
</style>