<template>
	<div class="fields_error" v-html="error_text"></div>
</template>

<script>
	export default {
		name: 'FieldsError',
		props: ['error_text']
	}
</script>

<style type="text/css" scoped>
	.fields_error {
		margin-top: 5px;
		font-weight: 500;
		text-align: center;
		min-height: 18px;
		color: red;
		font-size: 13px;
	}
</style>