<template>
	<div class="checkin">
		<CompanyInfos></CompanyInfos>
		<hr class="divider" />
		<ReservationInfos></ReservationInfos>
		<GuestData v-for="guest in parseInt(guests)" :key="guest" :guest_number="guest" ref="guests"></GuestData>
		<PrivacyPolicy ref="privacy"></PrivacyPolicy>
		<ButtonFullWidth :callback="sendWebCheckIn" :text="getTranslation('SEND_CHECK_IN')"></ButtonFullWidth>
		<FieldsError :error_text="error_text"></FieldsError>
	</div>
</template>

<script>
	import CompanyInfos from '@/components/CompanyInfos.vue'
	import ReservationInfos from '@/components/ReservationInfos.vue'
	import GuestData from '@/components/GuestData.vue'
	import PrivacyPolicy from '@/components/PrivacyPolicy.vue'
	import ButtonFullWidth from '@/components/ButtonFullWidth.vue'
	import FieldsError from '@/components/FieldsError.vue'

	import jQuery from 'jquery'
	let $ = jQuery

	export default {
		name: 'CheckIn',
		components: {
			CompanyInfos,
			ReservationInfos,
			GuestData,
			PrivacyPolicy,
			ButtonFullWidth,
			FieldsError
		},
		inject: ['$axios'],
		data() {
			return {
				error_text: ''
			}
		},
		computed: {
			guests: function() {
				return this.$store.state.guests;
			},
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
			sendWebCheckIn: function() {
				let all_ok = true;
				for(let guest_index in this.$refs.guests) {
					let guest_all_ok = this.$refs.guests[guest_index].checkData();
					if(!guest_all_ok) {
						all_ok = false;
					}
				}

				if(!all_ok) {
					this.error_text = this.getTranslation('FILL_ALL_FIELDS');
				} else {
					if(!this.$refs.privacy.checkData()) {
						all_ok = false;
					}

					if(!all_ok) {
						this.error_text = this.getTranslation('ACCEPT_PRIVACY_POLICY');
					} else {
						var guests = [];
						for(let guest_index in this.$refs.guests) {
							guests.push(this.$refs.guests[guest_index].getData());
						}

						var self = this

						self.$store.commit('setStatus', 0);

						var bodyFormData = new FormData();
						bodyFormData.append('vat', self.$store.state.vat);
						bodyFormData.append('reservation_id', self.$store.state.reservation_id);
						bodyFormData.append('reservation_room_id', self.$store.state.reservation_room_id);
						bodyFormData.append('guests', JSON.stringify(guests));

						self.$axios({
							method: 'post',
							url: '/checkin/'+self.$store.state.database+'/saveCheckInData',
							data: bodyFormData
						}).then(function (response) {
							if(response.data == 1) {
								self.$store.commit('setStatus', 2);
							} else {
								self.$store.commit('setStatus', -1);
							}
						});
					}
				}
				
			}
		},
		updated: function() {
			var self = this

			$('input').unbind('focus');
			$('input').focus(function() {
				$(this).removeClass('invalid_input');
				$(this).siblings('label').find('.error').html('');
				self.error_text = ''
			});

			$('select').unbind('focus');
			$('select').focus(function() {
				$(this).removeClass('invalid_input');
				$(this).siblings('label').find('.error').html('');
				if($(this).attr('id') == 'cc_expire_month' || $(this).attr('id') == 'cc_expire_year') {
					$('label[for=cc_expire]').find('.error').html('');
				}
				self.error_text = ''
			});
		},
	}
</script>

<style type="text/css" scoped>
	.checkin {
		width: calc(100% - 100px);
		margin: 0 auto;
	}

	.divider {
		border: none;
		border-top: 3px solid var(--mainColor);
		margin-top: 20px;
		margin-bottom: 30px;
	}
</style>