<template>
	<div class="company_infos">
		<div class="logo">
			<a :href="website" target="_blank"><img :src="logo" /></a>
		</div>
		<div class="infos">
			<div class="name" v-html="name"></div>
			<div class="address" v-html="address"></div>
			<div class="email" v-html="email"></div>
			<div class="phone" v-html="phone"></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CompanyInfos',
		inject: ['$baseURL'],
		computed: {
			logo: function() {
				return encodeURI(this.$baseURL + '/structures/'+this.$store.state.database+'/'+this.$store.state.company_id+'/company/' + this.$store.state.company_info.logo);
			},
			website: function() {
				return 'http://' + this.$store.state.company_info.website;
			},
			name: function() {
				return this.$store.state.company_info.name;
			},
			address: function() {
				return '<i class="fa-solid fa-location-dot" style="display: flex; justify-content: center; width: 15px; margin-right: 8px;"></i> ' + this.$store.state.company_info.address1 + ' - ' + this.$store.state.company_info.address2 + ' ' + this.$store.state.company_info.city + ' (' + this.$store.state.company_info.province + ') ';
			},
			email: function() {
				return '<i class="fa-solid fa-envelope" style="display: flex; justify-content: center; width: 15px; margin-right: 8px;"></i> ' + this.$store.state.company_info.email;
			},
			phone: function() {
				return '<i class="fa-solid fa-phone" style="display: flex; justify-content: center; width: 15px; margin-right: 8px;"></i>' + this.$store.state.company_info.phones;
			},
		}
	}
</script>

<style type="text/css" scoped>
	.company_infos {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.logo {
		margin-right: 30px;
	}

	.name {
		font-size: 18px;
		font-weight: 700;
	}

	.address {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}

	.email {
		margin-top: 2px;
		display: flex;
		align-items: center;
	}

	.phone {
		margin-top: 2px;
		display: flex;
		align-items: center;
	}

	@media only screen and (max-width: 599px) {
		.company_infos {
			flex-direction: column;
		}

		.logo {
			margin-right: 0px;
			margin-bottom: 15px;
		}
	}
</style>