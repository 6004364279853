<template>
	<div class="privacy_policy">
		<input id="privacy" type="checkbox" value="1" v-model="privacy">
		<label for="privacy"><a :href="policy_url" target="_blank">{{ getTranslation('PRIVACY') }}</a> &nbsp; <span class="error"></span></label>
	</div>
</template>

<script>
	import jQuery from 'jquery'
	let $ = jQuery

	export default {
		name: 'PrivacyPolicy',
		data() {
			return {
				privacy: false
			}
		},
		computed: {
			policy_url: function() {
				return 'https://www.isidorosoftware.com/files/wciprivacy/privacy_' + this.$store.getters.get_lang_code + '_' + this.$store.state.vat + '.html'
			},
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
			checkData: function() {
				let all_ok = true;

				if(this.privacy == false) {
					$('#privacy').addClass('invalid_input');
					$('#privacy').siblings('label').find('.error').html('<i class="fas fa-exclamation-triangle"></i>');
					all_ok = false;
				}

				return all_ok;
			}
		}
	}
</script>

<style type="text/css" scoped>
	.privacy_policy {
		margin-top: 25px;
	}

	.privacy_policy label a {
		color: #2f2f2f;
	}

	.privacy_policy input[type=checkbox]:checked,
	.privacy_policy input[type=checkbox]:not(:checked) {
		position: absolute;
		left: -9999px
	}

	.privacy_policy input[type=checkbox]:checked+label,
	.privacy_policy input[type=checkbox]:not(:checked)+label {
		display: block;
		position: relative;
		padding-left: 30px;
		cursor: pointer;
		line-height: 24px
	}

	.privacy_policy input[type=checkbox]:checked+label:before,
	.privacy_policy input[type=checkbox]:not(:checked)+label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 22px;
		height: 22px;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 3px 6px rgba(0,0,0,.16)
	}

	.privacy_policy input[type=checkbox]:checked+label:after,
	.privacy_policy input[type=checkbox]:not(:checked)+label:after {
		content: "\2713\0020";
		position: absolute;
		top: 4px;
		left: 3px;
		font-size: 20px;
		line-height: .8;
		color: #2f2f2f;
		font-weight: 900
	}

	.privacy_policy input[type=checkbox]:not(:checked)+label:after {
		opacity: 0;
		transform: scale(0)
	}

	.privacy_policy input[type=checkbox]:checked+label:after {
		opacity: 1;
		transform: scale(1)
	}

	.privacy_policy input[type=checkbox]:disabled:checked+label:before,
	.privacy_policy input[type=checkbox]:disabled:not(:checked)+label:before {
		opacity: .5;
		cursor: default
	}

	.privacy_policy input[type=checkbox].invalid_input+label:before {
		box-shadow: 1px 1px 6px #88002288 !important;
	}

	.privacy_policy label .error {
		color: #802;
		font-size: 12px;
		margin-right: 5px;
	}
</style>