<template>
	<div class="feedback_message" v-html="text" :style="{ color: '#'+color }"></div>
</template>

<script>
	export default {
		name: 'FeedbackMessage',
		props: ['color', 'text']
	}
</script>

<style type="text/css" scoped>
	.feedback_message {
		display: flex;
		flex-direction: column;
		box-shadow: 0 1px 9px rgba(0,0,0,.16);
		padding: 15px 40px;
		border-radius: 6px;
		border-left: 5px solid var(--mainColor);
		margin-top: 50px;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
	}
</style>