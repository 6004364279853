<template>
	<div class="button">
		<a @click="execute">{{ text }}</a>
	</div>
</template>

<script>
	export default {
		name: 'ButtonFullWidth',
		props: {
			text: String,
			callback: Function
		},
		computed: {
			
		},
		methods: {
			execute() {
				if (this.callback) {
					this.callback()
				}
			}
		}
	}
</script>

<style type="text/css" scoped>
	.button a {
		display: block;
		cursor: pointer;
		border: 2px solid var(--mainColor);
		background: var(--mainColor);
		color: #fff;
		padding: 8px 16px;
		font-size: 15px;
		font-weight: 600;
		text-align: center;
		border-radius: 3px;
		margin-top: 25px;
	}

	.button a:hover {
		background: transparent;
		color: var(--mainColor)
	}
</style>