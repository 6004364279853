<template>
	<div class="checkin-success">
		<CompanyInfos></CompanyInfos>
		<hr class="divider" />
		<ReservationInfos></ReservationInfos>
		<FeedbackMessage :color="'009900'" :text="error_text"></FeedbackMessage>
	</div>
</template>

<script>
	import CompanyInfos from '@/components/CompanyInfos.vue'
	import ReservationInfos from '@/components/ReservationInfos.vue'
	import FeedbackMessage from '@/components/FeedbackMessage.vue'

	export default {
		name: 'CheckInSuccess',
		components: {
			CompanyInfos,
			ReservationInfos,
			FeedbackMessage
		},
		computed: {
			error_text: function() {
				if(this.$store.state.status == 2) {
					return this.getTranslation('WEB_CHECK_IN_SUCCESS');
				} else if(this.$store.state.status == 3) {
					return this.getTranslation('WEB_CHECK_IN_ALREADY_SENT');
				}
				return '';
			}
		},
		methods: {
			getTranslation: function(key) {
				return this.$store.getters.get_translation(key);
			},
		}
	}
</script>

<style type="text/css" scoped>
	.checkin-success {
		width: calc(100% - 100px);
		margin: 0 auto;
	}

	.divider {
		border: none;
		border-top: 3px solid var(--mainColor);
		margin-top: 20px;
		margin-bottom: 30px;
	}
</style>