import { createApp } from 'vue'
import { store } from './store'
import axios from 'axios'
import App from './App.vue'

const baseURL = 'https://core.isidorosoftware.com'

const axiosInstance = axios.create({
    baseURL: 'https://core.isidorosoftware.com'
})

const app = createApp(App)
app.use(store)
app.provide('$baseURL', baseURL);
app.provide('$axios', axiosInstance);
app.mount('#app')